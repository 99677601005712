var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact"},[_vm._m(0),_c('div',{staticClass:"contact__box contact__inner"},[_c('ul',[_c('li',[_c('div',{staticClass:"list_inner",domProps:{"innerHTML":_vm._s(
            _vm.msg +
            " <div class='list_text'><span>Email</span><h3><a class='line__anim text-red-fluor' href='mailto:verastian0908@gmail.com'>verastian0908@gmail.com</a></h3> </div>"
          )}})]),_c('li',[_c('div',{staticClass:"list_inner",domProps:{"innerHTML":_vm._s(
            _vm.git +
            " <div class='list_text'><span>GitHub</span><h3><a class='line__anim text-red-fluor' href='https://github.com/Verastian'>github.com/Verastian</a></h3> </div>"
          )}})]),_c('li',[_c('div',{staticClass:"list_inner",domProps:{"innerHTML":_vm._s(
            _vm.link +
            " <div class='list_text'><span>Linkedin</span><h3><a class='line__anim text-red-fluor' href='https://www.linkedin.com/in/sebastian-vera-0908'>linkedin.com/sebastian-vera-0908</a></h3> </div>"
          )}})])])]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"contact__box"},[_c('FormContact')],1),_vm._m(3)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact__box contact__head text__content"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"small"},[_vm._v("Ponte en contacto")]),_c('h3',{},[_c('span',{staticClass:"square__position-about"},[_vm._v("C")]),_c('span',{staticClass:"text-red-fluor"},[_vm._v("onectate conmigo")]),_vm._v(", ")]),_c('h3',[_vm._v("con Confianza")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact__box contact__block"},[_c('span',{staticClass:"block-1 moving_effect",attrs:{"data-direction":"y","data-reverse":"no"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact__box contact__block"},[_c('div',{staticClass:"border moving_effect",attrs:{"data-direction":"x","data-reverse":"yes"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact__box contact__block"},[_c('div',{staticClass:"block-2 moving_effect",attrs:{"data-direction":"x","data-reverse":"no"}})])}]

export { render, staticRenderFns }